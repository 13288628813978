import DogCarousel from "../components/DogCarousel"

function Home() {
  return (
    <div className="home" id='home'>
        <DogCarousel />
    </div>
  )
}

export default Home
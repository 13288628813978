
function About() {
  return (
    <div className="about" id="about">
      <h2>About Me</h2>
      <br />
      <p>My name is <span className="name">Krystal Crain</span> and I've been grooming consistently for over 7 years now. I moved to Russellville almost two years ago and grooming dogs is what I love doing, I can't wait to build a connection with the pups in town!</p></div>
  )
}

export default About